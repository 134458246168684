<template>
    <modal ref="modalAgotados" titulo="Unidades Insuficientes" icon="box" tamano="modal-lg" no-aceptar adicional="Actualizar" @adicional="actualizar">
        <div class="row mx-0 text-general justify-center mb-4">
            Seleccione los productos a devolver
        </div>
        <div v-for="(p,index) in listado_productos" :key="index" class="row mx-0 mb-4">
            <div class="col-6 pr-0">
                <div class="row mx-0">
                    <div class="col-9 pr-0 d-flex">
                        <img :src="p.imagen" width="60px" height="60px" class="obj-cover" />
                        <div class="col d-flex flex-column justify-content-around">
                            <p class="text-general f-13 nombre-producto">
                                {{ p.nombre }}
                            </p>
                            <p class="text-general2 f-12">
                                {{ p.presentacion }}
                            </p>
                            <div class="row mx-0">
                                <p class="col px-0 text-general tres-puntos f-12">
                                    {{ convertMoneyTendero(p.valor_unitario,$tienda.idm_moneda) }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0">
                <div class="row mx-0 h-100 align-items-end">
                    <div class="col-5">
                        <p class="f-13 text-center descuento"> {{ p.cantidad_pedida }} </p>
                        <p v-if="p.stock_disponible === 0" class="text-general f-15 f-500 text-center"> AGOTADO </p>
                        <el-input-number
                        v-else
                        v-model="p.cantidad_maxima" size="small"
                        class="transparent-input"
                        :min="0"
                        :max="p.stock_disponible"
                        :step="p.salto"
                        :step-strictly="p.entero"
                        :precision="p.presicion"
                        />
                    </div>
                    <div v-if="p.stock_disponible > 0" class="col">
                        <el-tooltip content="Eliminar del carrito" placement="bottom" effect="light">
                            <i class="icon-cancel text-general-red cr-pointer f-20 pl-3" @click="p.cantidad_maxima = 0" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import PedidosNew from "~/services/pedidos/pedidos-tendero";
export default {
    data(){
        return {
            motivos:[],
            listado_productos:[],
            id_pedido:null
        }
    },
    methods: {
        toggle(productos){
            this.armar_array(productos)
            this.$refs.modalAgotados.toggle();
        },
        armar_array(array){
            let productos = []
            for (const p of array){
                productos.push({
                    id_pedido_producto: p.id,
                    id_producto:p.id_producto,
                    nombre:p.producto.nombre,
                    imagen:p.producto.imagen,
                    valor_unitario:p.unidad_final,
                    cantidad_pedida:parseFloat(p.cantidad),
                    cantidad_maxima:parseFloat(p.stock_disponible),
                    stock_disponible:parseFloat(p.stock_disponible),
                    presentacion:p.producto.presentacion,
                    entero:p.producto.cantidad_tipo === 1,
                    presicion:p.producto.cantidad_tipo === 1 ? 0 : 2,
                    salto:parseFloat(p.producto.cantidad_minima)
                })
            }
            this.listado_productos = productos
        },
        async actualizar(){
            try {

                let productos = []
                for (const p of this.listado_productos){

                    productos.push({
                        id_pedido_producto:p.id_pedido_producto,
                        id_producto:p.id_producto,
                        cantidad_nueva:p.cantidad_maxima,
                    })
                }

                if(productos.length == 0) return

                const {data} = await PedidosNew.actualizar_productos({productos})
                this.$emit('update')
                this.notificacion('Éxito','Pedido actualizado','success')
                this.$refs.modalAgotados.toggle();

            } catch (e){
                this.error_catch(e)
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.nombre-producto{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>
